import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('planejamento_acao', 'cadastro');

const planejamentoAcaoActions = {
  salvarAcao: { method: 'POST', url: `${basePath}` },
  atualizarAcao: { method: 'PUT', url: `${basePath}` },
  concluirAcao: { method: 'POST', url: `${basePath}/concluir_acao` },
  buscarTiposAcao: { method: 'GET', url: `${basePath}/campos/tipo_acao?tamanhoPagina={size}&numeroPagina={page}` },
  buscarTiposAcaoPorVerbasBloqueantes: {
    method: 'GET',
    url: `${basePath}/campos/tipo_acao_bloqueante?tamanhoPagina={size}&numeroPagina={page}`,
  },
  buscarDivisoes: { method: 'GET', url: `${basePath}/campos/divisao` },
  buscarDivisoesPorVisao: { method: 'GET', url: `${basePath}/campos/divisao/visao` },
  buscarRegionaissPorVisao: { method: 'GET', url: `${basePath}/campos/regional/visao` },
  buscarUnidadesNegocioDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/unidade_negocio` },
  buscarExtensaoDivisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/{idDivisao}/extensao` },
  buscarUnidadesNegocioEntidadesControlador: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio` },
  buscarUnidadesNegocioPorVisao: { method: 'GET', url: `${basePath}/campos/entidade_controlador/unidade_negocio/visao` },
  buscarClientesPagadores: { method: 'GET', url: `${basePath}/campos/cliente/pagador` },
  buscarPeriodos: { method: 'GET', url: `${basePath}/campos/periodo` },
  buscarPeriodosPlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/todos` },
  buscarPeriodosVigentePlanejamento: { method: 'GET', url: `${basePath}/campos/periodo/planejamento/vigente` },
  buscarContasCorrentesElegiveis: { method: 'GET', url: `${basePath}/conta_corrente/elegiveis{?id_produto*}{?idsPeriodos*}` },
  holdingEstaBloqueada: { method: 'GET', url: `${basePath}/conta_corrente/holding-esta-bloqueada` },
  executarAnalise: { method: 'PUT', url: `${basePath}/{idAcao}/executar_analise` },
  buscarGruposFornecedores: { method: 'GET', url: `${basePath}/campos/grupo_fornecedor` },
  buscarFornecedores: { method: 'GET', url: `${basePath}/campos/fornecedor` },
  buscarFornecedoresPagadores: { method: 'GET', url: `${basePath}/campos/fornecedor/pagador` },
  cancelarAcao: { method: 'PUT', url: `${basePath}/{idAcao}/cancelar` },
  buscarRegioes: { method: 'GET', url: `${basePath}/campos/regiao` },
  buscarCalculoAutomatico: { method: 'POST', url: `${basePath}/campos/valor_automatico` },
  buscarValorMonetarioAcaoRateio: { method: 'POST', url: `${basePath}/campos/calcular_valor_periodo` },

};

export default (resource) => resource(`${basePath}`, {}, planejamentoAcaoActions);

export const buscarHoldings = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/holding', parametros).doGet();

export const buscarExtensaoCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/extensao-cliente', parametros).doGet();

export const buscarClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/cliente', parametros).doGet();

export const buscarCnpjClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/cnpj-cliente', parametros).doGet();

export const buscarContasCorrentesElegiveis = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'conta_corrente/elegiveis', parametros).doGet();

export const obterCalendarioPorMnemonico = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/mnemonico', parametros).doGet();

export const buscarRegionais = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/regional', parametros).doGet();

export const buscarTodasRegionais = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'campos/todas_regionais', parametros).doGet();
