import getBasePath from '../../functions/api-resource';
import resourceBuilder from '../../functions/metadados-resource-builder';

const basePath = getBasePath('liquidacao', 'pagamento/listagem');

const recebimentoActions = {
  buscarPagamento: { methods: 'GET', url: `${basePath}/carregamento/{idPagamento}` },
  buscarPagamentosParciais: { methods: 'GET', url: `${basePath}/carregamento/{idPagamento}/parciais` },
  buscarAprovadoresPagamento: { methods: 'GET', url: `${basePath}/carregamento/aprovadores/{idPagamento}` },
};

export default (resource) => resource(`${basePath}`, {}, recebimentoActions);

export const listarPagamentosTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'totalizadores', parametros).doGet();

export const listarPagamentosPorCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-cliente', parametros).doGet();

export const listarPagamentosPorStatus = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-status', parametros).doGet();

export const listarPagamentosPorFornecedor = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-fornecedor', parametros).doGet();

export const listarPagamentosPorContrato = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'por-contrato', parametros).doGet();

export const listarPagamentosNivel2 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2', parametros).doGet();

export const listarPagamentosNivel2Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel2/simplificado', parametros).doGet();

export const listarPagamentosNivel3 = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel3', parametros).doGet();

export const listarPagamentosNivel3Simplificado = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'nivel3/simplificado', parametros).doGet();

export const listarRankingPagamentosPorHolding = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ranking-por-holding', parametros).doGet();

export const listarRankingPagamentosPorCategoria = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'ranking-por-categoria', parametros).doGet();

export const buscarHoldings = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'holdings', parametros).doGet();

export const buscarClientes = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'clientes', parametros).doGet();

export const buscarGraficoDashInicial = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'grafico', parametros).doGet();

export const buscarInfosClienteCardsTotalizadores = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'cliente-cards', parametros).doGet();

export const buscarGraficoPagamentosCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'grafico-pagamentos-cliente', parametros).doGet();

export const buscarGraficoTop5PagamentoPorTipoAcaoCliente = (parametros, resource) => resourceBuilder.buildGET(resource, basePath, 'grafico-top5-por-tipo-acao-cliente', parametros)
  .doGet();
